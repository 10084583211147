import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from '@environment';
import { ECookiesKeys } from '../enums/cookies-keys.enum';
import { CookieService } from 'ngx-cookie-service';
import { UrlLocationService } from './url-location.service';
import { NgswBypassService } from './ngsw-bypass.service';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	constructor(
		@Inject(DOCUMENT) private readonly _document: Document,
		@Inject(LOCALE_ID) private _locale: string,
		private readonly _cookiesSvc: CookieService,
		private readonly _urlLocationSvc: UrlLocationService,
		private readonly _ngswBypassSvc: NgswBypassService
	) {}

	// check if the current site is with the choosen language from cookie,
	// if not reload with current language and ngsw-bypass.
	public async setupLanguage(): Promise<void> {
		if (!environment.production) return;

		const baseHref = this._document.getElementsByTagName('base')[0].attributes[0].value;
		let storageLang = this._cookiesSvc.get(ECookiesKeys.Langauge);

		if (this._locale.toLowerCase() == 'en-us') this._locale = 'en';
		if (storageLang.toLowerCase() == 'en-us') storageLang = 'en';

		if (storageLang) {
			if (baseHref === `/` && this._locale === 'en' && storageLang !== 'en') {
				// Enter to the web with '/' but with localStorage inside
				const pageURL = this._urlLocationSvc.location.pathname;
				const queryParams = await this._ngswBypassSvc.addBypassToQueryParams(this._urlLocationSvc.location.search);

				if (!(pageURL.startsWith(storageLang) || pageURL.startsWith(`/${storageLang}`)))
					this._urlLocationSvc.location.href = `${storageLang}${pageURL}?${queryParams}`;
			} else if (this._locale !== storageLang && this._locale !== 'en')
				this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', 'copyleaks.com');
		} else this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', 'copyleaks.com'); // Covers first time going to the website
	}
}
