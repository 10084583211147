import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlLocationService } from './url-location.service';

@Injectable({
	providedIn: 'root',
})
export class NgswBypassService {
	constructor(
		private readonly _router: Router,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _urlLocationSvc: UrlLocationService
	) {}

	/**
	 * Adds the `ngsw-bypass=true` to the current route query parameters.
	 * If the parameter already exists, no action is taken.
	 */
	async addBypassToCurrentRoute(): Promise<void> {
		// Update the query parameters without relying on the full current URL
		await this._router.navigate([], {
			relativeTo: this._activatedRoute, // Use the current route as the base
			queryParams: {
				'ngsw-bypass': true,
			},
			queryParamsHandling: 'merge', // Merge with existing query parameters
		});

		// Reload only after the navigation completes
		this._urlLocationSvc.location.reload();
	}

	/**
	 * Adds `ngsw-bypass=true` to a full URL.
	 * @param queryParams - the query params from current loction.
	 * @returns Updated query params with `ngsw-bypass=true`.
	 */
	async addBypassToQueryParams(queryParams: string): Promise<URLSearchParams> {
		let queryP = new URLSearchParams(queryParams);
		// Add ngsw-bypass
		queryP.set('ngsw-bypass', 'true');
		return queryP;
	}
}
